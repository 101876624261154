@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

.DashboardHeroSection {
  background-color: var(--color-gray-800);
  padding: 12px 16px 28px 12px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .DashboardHeroSectionWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .DashboardHeroSectionLogo {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      box-shadow: 0 0 0 2.9px var(--color-gray-800);
      object-fit: cover;
      z-index: 1;
    }
    .DashboardHeroSectionStory {
      $logoSize: 52px;
      $logoGap: 10px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      min-width: 48px;

      .DashboardHeroSectionStoryCircle {
        position: absolute;
        width: $logoSize;
        height: $logoSize;
        border-radius: 50%;
        inset: 0;
        background: linear-gradient(
          45deg,
          #ffcc16 0%,
          #ff2a2a 25%,
          #da02bc 50%,
          #ff2a2a 75%,
          #ffcc16 100%
        );
        padding: 3px;
        animation: rotateInCircle 1.5s ease-in-out infinite;
      }

      @keyframes rotateInCircle {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .Logo {
        position: absolute;
        top: calc($logoGap / 2);
        left: calc($logoGap / 2);
        width: calc($logoSize - $logoGap);
        height: calc($logoSize - $logoGap);
        border-radius: 50%;
        box-shadow: 0 0 0 2.9px var(--color-gray-800);
        object-fit: cover;
        z-index: 1;
      }
    }

    .DashboardHeroSectionZoneTableName {
      display: flex;
      flex-direction: column;

      h4 {
        color: var(--color-text-white);
        @include text-truncate-ellipsis(1);
        word-break: break-all;
      }
      h6 {
        color: var(--color-text-low);
        @include text-truncate-ellipsis(1);
        word-break: break-all;
      }
    }
    .DashboardHeroSectionOptions {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .DashboardHeroSectionOptionButton {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 8px;
      border-radius: 48px;
      border: 1px solid var(--color-gray-600);
      background: var(--color-gray-700);
    }
  }

  .DashboardHeroButtons {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    justify-content: space-between;

    .MascotDashboardButton {
      padding: 4px 0;
    }

    > * {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 8px;
      align-items: center;
      width: calc(33.333% - 8px);

      .MascotDashboardButtonInfo {
        margin-left: 0;
        text-align: center;
      }

      .MascotDashboardButton {
        display: flex;
        padding: 4px 0;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        width: 100%;
      }

      .MascotDashboardButtonIcon {
        background: none;

        svg {
          max-width: 40px;
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .DashBoardChatButton {
    position: relative;

    .MascotDashboardButton {
      height: 100%;
    }

    .ChatButtonCount {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: -8px;
      right: -8px;
      background: var(--color-gradient-main);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      h6 {
        color: var(--color-text-black);
      }
    }
  }

  .CustomDashboardButton:last-child {
    svg {
      path {
        stroke: none;
      }
    }
  }

  .ButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
  }
}
