@import "src/assets/styles/mixins";

.Container {
  background-color: var(--color-gray-700);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  backdrop-filter: blur(12px);
  cursor: pointer;
  .Label{
    display: flex;
    align-items: center;
    gap: 14px;
  }
  img {
    flex-shrink: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .Notification {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--color-text-white);
    background: var(--color-admin-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h5 {
    color: var(--color-text-white);
    @include text-truncate-ellipsis(1);
    word-break: break-all;
  }
}
