.Container {
  position: fixed;
  background-color: var(--color-text-white);
  right: 20px;
  width: 36px;
  border-radius: 50%;
  transition: 0.6s;
  padding: 8px;
  box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: rotate(180deg);
    path{
      fill: var(--color-gray-600);
    }
  }
}
