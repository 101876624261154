@import "src/assets/styles/_variables";

.MockUp {
  width: 100vw;
  height: 100vh;
  background: #833ab4; /* fallback for old browsers */
  background: -webkit-linear-gradient(
                  to right,
                  #fcb045,
                  #fd1d1d,
                  #833ab4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
                  to right,
                  #fcb045,
                  #fd1d1d,
                  #833ab4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

div.marvel-device {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  &.iphone-x {
    width: 500px;
    height: 700px;
    padding: 0;

    &:before {
      width: calc(100% + 26px);
      height: calc(100% + 26px);
      left: -13px;
      top: -13px;
      z-index: 2;
      border-radius: 60px;
    }

    &:after {
      width: calc(100% + 46px);
      height: calc(100% + 46px);
      position: absolute;
      content: "";
      left: -23px;
      top: -23px;
      border-radius: 66px;
      box-shadow: rgba(0, 0, 0, 0.99) 0 20px 30px;
      background-color: var(--color-inverse-85);
    }

    .volume {
      left: -26px;
      z-index: 1;
      background: #515050;

      &:after,
      &:before {
        background: #515050;
      }
    }

    .sleep {
      right: -26px;
      z-index: 1;
      background: #515050;
    }

    .top-bar,
    .bottom-bar {
      left: -23px;
      width: calc(100% + 46px);
      z-index: 1;
      background: rgb(196 196 196 / 10%);
    }

    > div.notch {
      display: none;
    }

    div.screen {
      overflow: auto;
      z-index: 4;

      .MenuItemInfoContainer {
        margin-bottom: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .MenuDisplayCategorySelectionSection {
        border-radius: 40px 40px 0 0;
      }

      .WheelOfFortune {
        border-radius: 40px;
      }

      .SpeechToTextContainerHeader {
        border-radius: 40px 40px 0 0;
      }

      .ReservationVR {
        border-radius: 40px;
      }

      #WelcomeClient {
        border-radius: 40px;
      }

      .ReservationMap {
        border-radius: 40px;
      }
    }

    .MenuItem .MenuItemFooter {
      border-radius: 0 0 20px 20px;
      bottom: 15px;
      backdrop-filter: none;
    }

    Basket.isBasketEmpty {
      min-height: 100%;
    }

    .LogoSpinnerContainer {
      height: 700px;
    }

    .inner-shadow {
      width: calc(100% + 26px);
      height: calc(100% + 26px);
      position: absolute;
      top: -13px;
      overflow: hidden;
      left: -13px;
      border-radius: 56px;
      box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
      z-index: 4;

      &:before {
        content: none;
      }
    }

    .MascotDashboard {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      position: fixed;

      .BallsBg {
        border-radius: 40px;
      }
    }

    .MascotDashboardMain {
      height: calc(100% - 60px);
      position: relative;
    }

    .MenuItem {
      padding-bottom: 100px;
    }

    .CalendarModalContainer {
      .Modal {
        padding: 70px 0 30px;
      }

      .ModalHeader {
        display: flex;
      }
    }

    .MenuItemSelectedContainerOverlay {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      overflow: hidden;

      .MenuItemSelected {
        max-height: 90%;
        min-height: 40%;
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
      }
    }

    .SpeechToTextContainerFooter {
      .ModalBackground {
        .ModalContainer {
          width: auto;

          .ModalFooter {
            height: 60px;
          }
        }
      }
    }

    .SignUpMainForm {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .ModalBackground .ModalContainer .Modal .ModalBody {
        padding: 0;
        max-height: 560px;
      }
    }

    .FooterContainer {
      .ModalBackground .ModalContainer .Modal .ModalBody {
        padding: 0;
        max-height: 560px;
      }
    }

    .ModalBackground {
      width: 100%;
      height: 100%;
      align-items: flex-end;
      border-radius: 40px;

      .ModalContainer {
        width: 100%;
        max-width: 100%;
        border-radius: 16px 16px 40px 40px;

        .ModalHeader {
          padding: 0 16px;
        }

        .ModalBody {
          height: calc(100% - 64px - 70px);
          padding: 0 16px;

          .PaymentContentWrapper {
            height: calc(100% - 64px - 70px);
          }

          .ConfirmModalBody {
            width: auto;
          }

          .PayWithButton,
          .GoToDashboardBtn {
            margin-top: 120px;
            margin-bottom: 30px;
          }
        }

        .MenuFilterModalBody {
          min-width: unset;
        }
      }
    }

    .GuestModalBackground {
      overflow: hidden;
      width: 100%;
      height: 100%;
      align-items: flex-end;
      border-radius: 40px;

      .GuestModalContainer {
        width: 100%;
        max-width: 100%;
        border-radius: 16px 16px 40px 40px;

        .GuestModalHeader {
          padding: 0 16px;
        }

        .GuestModalBody {
          height: calc(100% - 64px - 70px);
          padding: 0 16px;

          .PaymentContentWrapper {
            height: calc(100% - 64px - 70px);
          }

          .ConfirmModalBody {
            width: auto;
          }

          .PayWithButton,
          .GoToDashboardBtn {
            margin-top: 120px;
            margin-bottom: 30px;
          }
        }

        .MenuFilterModalBody {
          min-width: unset;
        }
      }
    }
  }
}
