@keyframes bounceEffectWidthForMedium {
  0%,
  100% {
    padding: 8px 16px 8px 16px;
  }
  25% {
    padding: 8px 22px 8px 16px;
  }
  50% {
    padding: 8px 18px 8px 16px;
  }
  75% {
    padding: 8px 20px 8px 16px;
  }
}

@keyframes closeEffectForMedium {
  0% {
    width: 107px;
  }
  50% {
    width: 68px;
  }
  100% {
    width: 72px;
  }
}

@keyframes bounceEffectWidthForSmall {
  0%,
  100% {
    padding: 6px 8px 6px 8px;
  }
  25% {
    padding: 6px 12px 6px 8px;
  }
  50% {
    padding: 6px 8px 6px 8px;
  }
  75% {
    padding: 6px 10px 6px 8px;
  }
}

@keyframes closeEffectForSmall {
  0% {
    width: 45px;
  }
  50% {
    width: 38px;
  }
  100% {
    width: 40px;
  }
}

.AnimatedAddButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-admin-primary);
  border-radius: var(--radius-preferred-border-radius);
  border: none;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 300ms ease, color 300ms ease;

  &.Small {
    padding: 4px 8px;
    border-radius: 6px;
    gap: 2px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    .AnimatedAddButtonText {
      color: white;
      font-size: 11px;
    }
    &.clicked {
      animation: bounceEffectWidthForSmall 1000ms ease;
      background-color: black;
      color: white;
    }
    &.closing {
      animation: closeEffectForSmall 350ms ease forwards;
    }
    .HappyBasketIcon {
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
    }
  }

  &.Medium {
    position: absolute;
    right: 12px;
    bottom: 12px;
    padding: 8px 24px;
    gap: 12px;
    .AnimatedAddButtonText {
      color: white;
    }
    &.clicked {
      animation: bounceEffectWidthForMedium 1000ms ease;
      background-color: black;
      color: white;
    }
    &.closing {
      animation: closeEffectForMedium 350ms ease forwards;
    }
    .AnimatedAddButtonIcon {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
    }
  }
}

.AnimatedAddButtonText {
  color: white;
  white-space: nowrap;
}

.AnimatedAddButtonBadge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-background-black);
  color: var(--color-text-white);
}
