.GuestAuthModalHeader {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  padding-bottom: 12px;
  .GuestAuthModalHeaderTitle {
    color: var(--color-text-black-high);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-wrap: nowrap;
  }

}

.GuestAuthModal {
  .InfoModalBackground {
    &.isBottom {
      .GuestModalContainer {
        width: 100%;
        max-width: 100%;
        border-radius: 30px 30px 0 0;
      }
    }
    &.isSlided{
      left: 80%;
    }
  }

  .ModalContainer {
    width: 100vw;
  }

  .ModalClassname {
    background-color: var(--color-text-white);
    padding: 24px 16px 30px;
  }

  .ContainerClassname {
    border-radius: 30px;
  }

  .ModalBackground {
    z-index: 101;
  }
}

.GuestAuthModalBody {
  padding: 28px 0 48px 0;

}

.GuestAuthModalFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;

  .GuestAuthModalFooterSignIn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    padding: 6px 0;

    .GuestAuthModalFooterTitle {
      color: var(--color-text-black-high);
    }

    .GuestAuthModalFooterLogin {
      color: var(--color-text-black-high);
      text-decoration-line: underline;
      text-decoration-style: solid;
    }
  }
}

.GuestAuthModalProfileButton {
  background: var(--color-preferred-secondary-bg);
  width: 40px;
  height: 40px;
  border: 3px solid var(--color-text-white);

  svg path {
    stroke: var(--color-preferred-secondary-text);
    fill: var(--color-preferred-secondary-text);
  }

  &:active {
    background: var(--color-preferred-secondary-bg);
  }

  &:hover {
    background: var(--color-preferred-secondary-bg);
  }

  &:hover,
  &:active {
    svg path {
      stroke: var(--color-preferred-secondary-text);
      fill: var(--color-preferred-secondary-text);
    }
  }
}

.UserAvatarIcon {
  width: 80px;
  height: 80px;
  position: absolute;
  object-fit: cover;
  bottom: 0;
}

.GuestAuthModalBodyInputContainer {
  .InputControlContainer {
    margin-bottom: 16px;
  }
}

.GuestAuthModalProfileFileInput {
  display: none;
}

.GuestAuthModalFooterButton {
  &:active {
    scale: 0.95;
  }

  svg {
    stroke: var(--color-preferred-secondary-text);
  }

  .PrimaryButtonText {
    color: var(--color-preferred-secondary-text);
  }
}

.GuestLoginModalPrivacyAndPolicyModal {
  margin-top: 40px;
  margin-bottom: 12px;

  .GuestLoginModalPrivacyAndPolicyModalTitle {
    color: var(--color-light-mode-black);
    cursor: pointer;
  }

  .ModalContainer {
    height: calc(100vh - 100px);
  }
}

.GuestLoginModalPrivacyAndPolicyModalLink {
  font-size: 12px;
  font-weight: 500;

  strong {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
  }
}
