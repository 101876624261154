@import "src/assets/styles/variables";

.SignUpMainForm {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .Modal.hasOnlyHeader .ModalBody {
    padding: 0;
  }

  .ModalContainer {
    max-height: 90%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 30px;
  }


  .SignUpCreateAnAccountForm {
    display: flex;
    flex-direction: column;

    .SignUpPhoneOrEmailSwitch {
      .FilterSwitchWrapper {
        > label {
          font-size: 14px;
        }
      }
    }

    .react-international-phone-country-selector-button {
      border-radius: 30px 0 0 30px;
      background-color: var(--color-mini-button);
      border-left: 1px solid var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);
    }

    .react-international-phone-input {
      border-radius: 0 30px 30px 0;
      background-color: var(--color-mini-button);
      border-right: 1px solid var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 1000px var(--color-mini-button) inset;
      }
    }

    .SignUpMethodInputGroups {
      display: flex;
      margin-top: 40px;
      flex-direction: column;
      gap: 16px;
    }
  }

  .SignUpSubTitle {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 100px;
    color: var(--color-inverse-50);
  }

  .LoginLink {
    margin-left: 12px;
  }

}

.SignUpFormTermsAndConditions {
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 32px;

  .CheckboxContainer {
    .Checkbox {
      .CheckboxIcon {
        border: 1px solid var(--color-text-black);
      }
    }

    .isChecked {
      .CheckboxIcon {
        border: none;
      }
    }
  }

  .CheckboxError {
    .Checkbox {
      .CheckboxIcon {
        border: 1px solid var(--color-error);
      }
    }
  }

  .SignUpFormError {
    color: var(--color-error);
    margin-top: 3px;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .SignUpFormTermsAndConditionsText {
      font-size: 14px;
      font-weight: 500;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: $mobile) {
  .SignUpMainForm {
    .Modal.hasOnlyHeader .ModalBody {
      padding: 0;
    }
  }
}