@import "src/assets/styles/mixins";

@mixin positionStyles {
  position: absolute;
  text-align: center;
  top: 4px;
  border-radius: 50%;
  z-index: 1;
}

@mixin imgStyles {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.DashboardOrdersContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .DashboardOrdersHeaders {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 40px;
    .DashboardOrdersActionButtons {
      display: flex;
      gap: 8px;

      .UnSelectBtn {
        background-color: var(--color-text-white);
        padding: 12px 16px;
        border-radius: 10px;

        h6 {
          white-space: nowrap;
        }
      }
    }
  }

  .DashboardOrdersDeleteIcon {
    width: 40px;
    height: 40px;
    background-color: var(--color-red-15);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .DashboardOrders {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    margin-top: 16px;
    cursor: grab;
    margin-bottom: 150px;

    &::-webkit-scrollbar {
      display: none;
    }

    .OrderItemCard {
      height: 108px;
      position: relative;
      border-radius: 8px;

      .GuestPaidInfo {
        width: 100%;
        height: 100%;
        display: none;
        border-radius: 8px;
        background: rgba(76, 191, 74, 0.7);
        position: absolute;
        top: 0;

        &.other {
          display: block;
        }

        .GuestPaidInfoWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          .GuestPaidInfoGuestImage {
            width: 41px;
            height: 41px;
            flex-shrink: 0;
            border-radius: 41px;
            overflow: hidden;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .GuestPaidInfoText {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            .PaidIcon {
              flex-shrink: 0;
              position: absolute;
              background-color: #aad4a1;
              border-radius: 50%;
              text-align: center;
              top: 4px;
              right: 4px;
              width: 24px;
              height: 24px;
              color: var(--color-text-white);
            }

            .Medium {
              color: var(--color-text-white);
              text-align: center;
            }
          }
        }
      }
      .GuestInProgressInfo {
        width: 100%;
        height: 100%;
        display: none;
        border-radius: 8px;
        position: absolute;
        top: 0;

        &.other {
          display: block;
        }

        .GuestInProgressInfoWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          .GuestInProgressInfoGuestImage {
            width: 41px;
            height: 41px;
            flex-shrink: 0;
            border-radius: 41px;
            overflow: hidden;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .GuestInProgressInfoText {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            .InProgressIcon {
              flex-shrink: 0;
              position: absolute;
              background-color: rgba(255, 221, 0, 0.4);
              border-radius: 50%;
              top: 4px;
              right: 4px;
              width: 24px;
              height: 24px;
              color: #ffde02;
              display: flex;
              justify-content: center;
            }

            .Medium {
              color: var(--color-primary);
              text-align: center;
              margin: 6px;
              line-height: 18px;
            }
          }
        }
      }

      .ConfirmInfo {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: var(--color-error);
        text-align: center;
        z-index: 7;
        font-weight: 500;
        &.other {
          display: block;
        }
      }

      .DeniedIcon {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 10;
      }

      &.isSelected {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: rgba(255, 199, 0, 0.75);
          z-index: 6;
        }
      }

      &.isDenied,
      &.isDeleted {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 8px;
          z-index: 5;
        }
      }
      &.isInProgress {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 8px;
        }
      }

      &.isSelected {
        &.denied,
        &.deleted {
          .ConfirmInfo {
            z-index: 7;
          }
        }
      }

      > img {
        border-radius: 10px;
        @include imgStyles;
      }

      .OrderItemSelect {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      .OrderItemStatus {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #f0f0f0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        right: 4px;
        @include positionStyles;
      }

      .OrderItemStatus.isWaiting {
        background-color: #fff8c9;
      }

      .OrderItemStatus.isWaiting::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fcec84;
      }

      .OrderItemStatus.isWaiting::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #fd0;
      }

      .OrderItemStatus.isConfirmed {
        background-color: #cae3ca;
      }

      .OrderItemStatus.isConfirmed::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #95ce94;
      }

      .OrderItemStatus.isConfirmed::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #4cbf4a;
      }
    }

    .DashboardOrdersNoOrder {
      margin: 24px 0 14px 8px;
    }
  }
}

.OrderItemName {
  border-radius: 0 0 8px 8px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 22px;
  position: absolute;
  bottom: 0;
  padding: 4px 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 4px;

  h6 {
    @include text-truncate-ellipsis(1);
    word-break: break-all;
  }
}
