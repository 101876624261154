.FunZoneGames {
  background-color: #1f1f1f;
  position: relative;
  height: 100%;
  .FunZoneGamesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0px;
    h2 {
      color: var(--color-text-white);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
  }
  .FunZoneGamesBody {
    padding: 40px 16px 0px;
    h6 {
      color: #b3b3b3;
    }
    .FunZoneGamesBodyGames {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      gap: 16px;
      .FunZoneGamesBodyGame {
        display: flex;
        position: relative;
        width: 100%;
        min-height: 120px;
        max-height: 150px;
        border-radius: 16px;
        overflow: hidden;
        .FunZoneGamesBodyGamesText {
          padding: 24px 26px;
          h1,
          h3 {
            color: #fff;
            text-align: start;
            width: 180px;
          }
          h3 {
            margin-top: 8px;
            word-break: break-word;
          }
        }
        img{
          position: absolute;
          right: 0;
          bottom: 0;
        }

      }
      .FunZoneGamesBodySpinnerGame {
        border: 2px solid #9747ff;
        background: rgba(151, 71, 255, 0.2);
        backdrop-filter: blur(13px);
      }
      .FunZoneGamesBodyTicTacGame{
        border-radius: 16px;
        border: 2px solid #4CA1AF;
        background: rgba(76, 161, 175, 0.20);
        backdrop-filter: blur(13px);
        img{
          right: 14px;
          top: 25px;
        }
      }

      .FunZoneGamesBodyMindGame {
        border: 2px solid var(--Light-Mode-Primary, #fd0);
        background: rgba(255, 221, 0, 0.2);
        backdrop-filter: blur(13px);
      }
      .FunZoneGamesBodyTowerGame {
        border: 2px solid #53838b;
        background: rgba(83, 131, 139, 0.2);
        backdrop-filter: blur(13px);
        img{
          right: 22px;
        }
      }
    }
  }
}
