@import "src/assets/styles/mixins";

.Business {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.BusinessBodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px 12px 0 0;
  width: 100%;
  padding-bottom: 30px;
  background: var(--color-text-white);
  .BusinessVR {
    margin: 40px 16px 0;

    .GuestInfoFormDetailsVirtualTourPreview {
      height: 142px;
      width: 100%;
      margin: 20px 0;
    }
  }
}

.BusinessHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 43px 5px 5px;
}

.BusinessLogoImageWrapper {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 3px solid var(--color-text-white);
  position: absolute;
  top: -50px;
  left: 16px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-position: center;
    object-fit: cover;
  }
}

.BusinessProfileName {
  width: 100%;
  text-align: left;
  padding: 20px 16px 0;
  @include text-truncate-ellipsis(2);
  word-break: break-word;
}

.BusinessHeaderAddress {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 10px;
  margin-top: 7px;

  h6 {
    padding-left: 5px;
    @include text-truncate-ellipsis(2);
    word-break: break-word;
  }
}

.BusinessMenuButtonWrapper {
  display: flex;
  margin: 20px 16px 0;
  gap: 10px;
}

.BusinessWorkingHoursInfo {
  padding: 0 16px;
  margin: 20px 0 0;

  &.isDifferent {
    padding: 20px;
    background: var(--color-mini-button);
    border-radius: 10px;
    margin: 20px 16px 0;

    .BusinessWorkingHoursTitle {
      margin-bottom: 20px;
    }
  }
}

.BusinessNoInfoContainer {
  flex: 1;
}

.WorkingHoursInfoWrapper {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div + div {
    margin-left: 10px;
  }

  .WorkingHoursInfo {
    min-width: 150px;
  }
}

.BusinessContacts {
  padding: 40px 16px 0 16px;

  .BusinessContactsButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    & > *:nth-child(odd) {
      margin-right: 5px;
    }

    & > *:nth-child(even) {
      margin-left: 5px;
    }
    .CTAButton.typeL {
      justify-content: start;
    }
  }

  .BusinessContactButton {
    background: var(--color-text-white);
    border: 1px solid var(--color-stroke);
    border-radius: 10px;
    width: calc(50% - 5px);
    margin-bottom: 10px;

    .CTAButtonIcon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.BusinessAddress {
  margin: 20px 16px 0;
}

.BusinessLocationMap {
  margin-top: 10px;
  padding: 0 16px;
  border-radius: 10px;
  cursor: pointer;

  img {
    width: 100%;
    height: 130px;
    max-height: 130px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.BusinessDescription {
  padding: 0 16px;
  line-height: 15px;
  margin-top: 20px;
  //margin-bottom: 60px;

  h6 {
    margin-bottom: 16px;
  }

  > p {
    color: var(--color-inverse-50);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: pre-line;
  }
}

.BusinessProfileOtherImagesContainer {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  height: 357px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 40px;

  &::-webkit-scrollbar {
    display: none;
  }

  .BusinessProfileOtherImage {
    margin: 0 8px;
    height: 100%;
    min-width: 312px;
    max-width: 312px;
    border-radius: 24px;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 16px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 24px;
    }
  }
}
