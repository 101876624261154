.BestChoiceModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  margin-bottom: 20px;

  h1 {
    color: var(--color-text-white);
  }

  .BestChoiceModalHeaderIcons {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

  }
}

.BestChoiceModalBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .LoyaIconWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12px;

    .LoyaIcon {
      width: 28px;
      height: 28px;
    }
  }

  .ResultsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 8px;
  }

  .AddToBasketButton {
    height: 43px;
    width: 100%;
    margin-top: 16px;
  }
}

.ResultItems:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-30);
}

.ResultItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  word-break: break-word;
  padding: 8px 0 20px;
  gap: 16px;

  .ResultItemTitle {
    margin: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    h5 {
      align-self: stretch;
      color: var(--color-text-black-high);
    }

    h6 {
      align-self: stretch;
      color: var(--color-text-black-high);
    }
  }
}

.AddedToCart {
  color: var(--color-success);
  margin-top: 16px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.ResultItemDetails {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;

  > div:first-child {
    margin-left: 12px;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
  }
}
