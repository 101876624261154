.SpeechToTextContainerHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
  position: fixed;
  background: var(--color-gray-20);
  width: 100%;
  z-index: 1;

  .BackButtonWrapper {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 16px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .BackButton {
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: var(--color-gray-20);
      padding: 0;

      svg {
        path {
          stroke: var(--color-gray-600);
        }
      }
    }
  }

  .SpeechToTextContainerHeaderTitle {
    text-align: center;
    color: var(--color-text-black-high);
  }

  .SpeechToTextContainerHeaderActions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 20px;
    gap: 12px;

    .TrashContainer {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: var(--color-gray-20);

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: var(--color-gray-600);
        }
      }
    }
  }

  .BasketContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--color-gray-20);

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: var(--color-gray-600);
      }
    }

    .BasketButtonCountWrapper {
      position: absolute;
      padding: 0 5px;
      border-radius: 10px;
      top: -12px;
      right: 14px;
      background: var(--color-preferred-secondary-bg);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: var(--color-text-black-high);
        text-align: center;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: var(--color-gray-600);
      }
    }
  }
}
