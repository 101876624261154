@import "src/assets/styles/_variables";

.CTAButton {
  display: flex;
  align-items: center;
  padding: 18px 15px;
  border-radius: 12px;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;

  &.typeA {
    background: var(--color-text-black);
    justify-content: space-between;

    .Price {
      h4 {
        line-height: 20px;
        color: var(--color-text-yellow);
      }
    }

    &:active {
      background: var(--color-primary);

      .CTAButtonName {
        color: var(--color-text-black);
      }

      .Price {
        h4 {
          color: var(--color-text-black);
        }
      }
    }
  }

  &.typeB {
    justify-content: space-between;
    background: var(--color-primary);
    &:disabled {
      cursor: not-allowed;
    }
    .CTAButtonName {
      color: var(--color-text-black);
    }
    &.isLoading {
      pointer-events: none;
      justify-content: center;
      svg {
        stroke: var(--color-preferred-secondary-text);
      }
    }
  }

  &.typeC {
    border-radius: 8px;
    padding: 14px;
    max-width: 119px;

    .CTAButtonName {
      line-height: 16px;
    }

    &:active {
      background: var(--color-background-black);

      .CTAButtonName {
        color: var(--color-text-white);
      }
    }
  }

  &.typeD {
    border-radius: 12px;
    max-width: 76px;
    padding: 25px 22px;
    align-items: center;

    .CTAButtonName {
      font-size: 18px;
      line-height: 26px;
    }

    &:active {
      background: var(--color-background-light-mode);

      .CTAButtonName {
        color: var(--color-text-black);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
      background: var(--color-background-light-mode);

      .CTAButtonName {
        color: var(--color-text-black);
      }
    }
  }

  &.typeC,
  &.typeD {
    justify-content: center;
    background: var(--color-primary);
    &:disabled {
      background: var(--color-light-gray);
      .CTAButtonName {
        color: var(--color-text-black);
      }
    }
    .CTAButtonName {
      color: var(--color-text-black);
      font-weight: 600;
    }
  }
  &.typeE {
    background: var(--color-text-black);
    justify-content: space-between;
    transition: background-color 0.5s ease;

    .ArrowIconContainer {
      svg path {
        stroke: var(--color-text-white);
      }
    }
    &.isLoading {
      pointer-events: none;
      justify-content: center;
      svg {
        stroke: var(--color-preferred-secondary-text);
      }
    }

    &:disabled {
      background: var(--color-light-gray);

      .CTAButtonName {
        color: var(--color-text-black);
      }

      .ArrowIconContainer {
        svg path {
          stroke: var(--color-text-black);
        }
      }
    }

    &:active {
      //  background: var(--color-primary);
      //
      //  .CTAButtonName {
      //    color: var(--color-text-black);
      //  }
      //
      //  .ArrowIconContainer {
      //    svg path {
      //      stroke: var(--color-text-black);
      //    }
      //  }
    }
  }

  &.typeF {
    padding: 12px 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .CTAButtonName {
      color: var(--color-text-black);
    }

    .CTAButtonIcon {
      width: 24px;
      height: 24px;
    }

    &:active {
      background: var(--color-gray);
    }
  }

  &.typeG {
    border: 1px solid var(--color-stroke);
    background: var(--color-text-white);
    justify-content: space-between;
    padding: 6px 15px 6px 6px;

    .CTAButtonIcon {
      background: var(--color-mini-button);
      width: 44px;
      height: 44px;
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }

    .CTAButtonName {
      color: var(--color-text-black);
      flex-grow: 1;
      text-align: start;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &:active {
      border: 1px solid transparent;
      background: var(--color-mini-button);

      .CTAButtonIcon {
        background: var(--color-background-light-mode);
      }
    }
  }

  .CTAButtonIcon {
    margin-right: 5px;
  }

  &.typeH {
    width: max-content;
    padding: 9px 40px;
    background-color: var(--color-admin-primary-15);
    border: 1px solid var(--color-admin-primary);
    border-radius: 6px;

    .CTAButtonName {
      font-size: 12px;
      line-height: 16px;
      color: var(--color-text-black);
      font-weight: 500;
    }
  }

  &.typeL {
    border: 1px solid var(--color-stroke);
    background: var(--color-text-white);
    justify-content: space-between;
    padding: 6px 15px 6px 6px;
    height: 56px;

    .CTAButtonIcon {
      background: var(--color-mini-button);
      width: 44px;
      height: 44px;
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin-right: 0;
    }

    .CTAButtonName {
      color: var(--color-text-black);
      flex-grow: 1;
      text-align: start;
      padding: 0 10px;
    }

    &:active {
      border: 1px solid transparent;
      background: var(--color-mini-button);

      .CTAButtonIcon {
        background: var(--color-background-light-mode);
      }
    }
  }
  &.typeM {
    background-color: var(--color-primary);
    padding: 8px 16px;
    &:disabled {
      cursor: not-allowed;
      background: var(--color-mini-button);
      h3,
      h4,
      h6 {
        color: var(--color-inverse-50);
      }
    }
  }
  &.typeN {
    background-color: var(--color-preferred-secondary-bg);
    padding: 13px 16px 14px 16px;
    gap: 4px;
    &.isLoading {
      pointer-events: none;
      justify-content: center;
      svg {
        stroke: var(--color-preferred-secondary-text);
      }
    }
    &:disabled {
      background-color: var(--color-light-gray);
      cursor: not-allowed;
    }
    .CTAButtonName {
      color: var(--color-preferred-secondary-text);
      margin-right: auto;
      text-align: left;
    }
    .Price {
      h6 {
        color: var(--color-preferred-secondary-text);
      }
    }
  }

  &.Success {
    background: var(--color-success);
    flex-direction: row-reverse;
    height: 43px;
    .CTAButtonName {
      color: var(--color-text-white);
    }
    .CTAButtonIcon {
      width: 24px;
      height: 24px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .Price {
      h6 {
        color: var(--color-text-white);
      }
    }
  }

  &.Error {
    background: var(--color-error);

    .CTAButtonName {
      color: var(--color-text-white);
    }
  }

  &.typeZ {
    .CTAButtonIcon {
      width: 24px;
      height: 24px;
      margin-right: 0;
    }

    &:hover {
      background: var(--color-gray);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-text-white);
    border-radius: 8px;
    width: 46px;
    height: 46px;
  }
  &.typeJ {
    width: max-content;
    background-color: var(--color-preferred-secondary-bg);
    padding: 4px 8px;
    gap: 4px;
    border-radius: 6px;
    h4 {
      display: none;
    }
  }
  &.typeY {
    background-color: var(--color-gray-600);
    padding: 20px;
    border-radius: 16px;
    height: 64px;
    min-height: 64px;
    &:disabled{
      background-color: var(--color-gray-30);
      .GuestPayDetail{
        h3,h4{
          color: var(--color-text-black-low);
        }
        h6{
          color: var(--color-text-white);
        }
      }
      .Price{
        h4{
          color: var(--color-text-black-low);
        }
      }
    }
    &.isLoading{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      svg{
        stroke: var(--color-text-white);
      }
    }
  }
  &.typeR {
    background-color: var(--color-preferred-secondary-bg);
    align-items: center;
    display: flex;
    gap: 4px;
    width: calc(100% - 32px);
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 56px;
    padding: 0 16px;
    position: fixed;
    bottom: 32px;
    left: 0;
    margin: 0 16px;
    z-index: 100;
    &.isLoading {
      pointer-events: none;
      justify-content: center;
    }
    &:active {
      scale: 0.95;
    }
    .CTAButtonIcon {
      path {
        stroke: var(--color-preferred-secondary-text);
      }
    }
    .CTAButtonName {
      color: var(--color-preferred-secondary-text);
    }
  }
}

.CTAButtonName {
  color: var(--color-text-white);
}
