.IosSwitchContainer {
  border: none;
  width: 51px;
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  .IosSwitch {
    background-color: var(--color-gray-40);
    border-radius: 40px;
    height: 33px;
    padding: 3px;
    width: 51px;
    &.IsDarkMode{
      background-color: var(--color-gray-600);
    }
    .IosSwitchSlider {
      border-radius: 13px;
      height: 27px;
      width: 27px;
      background-color: var(--color-background-light-mode);
      transition: transform .3s;
    }
    &.isChecked {
      background-color: var(--color-primary);
      .IosSwitchSlider {
        transform: translateX(18px);
      }
    }
  }
  &.isDisabled {
    pointer-events: none;
    cursor: not-allowed;

    .IosSwitch {
      background-color: #292828;
    }

    .IosSwitchSlider {
      background-color: #414141;
    }
  }
  .IosSwitchLabel {
    margin-left: 12px;
  }
}


