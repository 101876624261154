.Logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.Notification{
  position: absolute;
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 1000px;
  border: 1px solid var(--color-text-white);
  background: var(--color-admin-primary);
}