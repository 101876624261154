@import "src/assets/styles/_variables";

:root {
  &[data-theme="light"] {
    --color-preferred-primary-bg: #{$preferred_primary_color_bg};
    --color-preferred-secondary-bg: #{$preferred_secondary_color_bg};
    --color-preferred-primary-text: #{$preferred_primary_color_text};
    --color-preferred-secondary-text: #{$preferred_secondary_color_text};
    --radius-preferred-border-radius: #{$preferred_primary_border_radius};

    --radius-preferred-border-radiusA: #{$preferred_border_radiusA};
    --radius-preferred-border-radiusB: #{$preferred_border_radiusB};
    --radius-preferred-border-radiusC: #{$preferred_border_radiusC};
    --radius-preferred-border-radiusD: #{$preferred_border_radiusD};
    --radius-preferred-border-radiusE: #{$preferred_border_radiusE};
    --radius-preferred-border-radiusF: #{$preferred_border_radiusF};
    --radius-preferred-border-radiusG: #{$preferred_border_radiusG};
    --radius-preferred-border-radiusL: #{$preferred_border_radiusL};
    --radius-preferred-border-radiusM: #{$preferred_border_radiusM};
    --margin-preferred-marginMR: #{$preferred_marginMR};

    --width-inner-width: #{$width_Inner_Width};
    --color-mode: #{$colorL_Mode};
    --color-primary: #{$colorL_Primary};
    --color-primary-5: #{$colorL_Primary_5};
    --color-primary-15: #{$colorL_Primary_15};
    --color-admin-primary: #{$colorL_Admin_Primary};
    --color-admin-primary_15: #{$colorL_Admin_Primary_15};
    --color-admin-primary-hover: #{$colorL_Admin_Primary_Hover};
    --color-admin-primary-disabled: #{$colorL_Admin_Primary_Disabled};
    --color-admin-primary-15: #{$colorL_Admin_Primary_15};
    --color-inverse-100: #{$colorL_Inverse_100};
    --color-inverse-85: #{$colorL_Inverse_85};
    --color-inverse-75: #{$colorL_Inverse_75};
    --color-inverse-50: #{$colorL_Inverse_50};
    --color-inverse-25: #{$colorL_Inverse_25};
    --color-discount-price: #{$colorL_Discount_Price};
    --color-blur-black-5: #{$colorL_Blur_Black_5};
    --color-blur-white-30: #{$colorL_Blur_White_30};
    --color-glass-white-90: #{$colorL_Glass_White_90};

    --color-light-black: #{$colorL_Light_Black};
    --color-text-black: #{$colorL_Text_Black};
    --color-text-black-high: #{$colorL_Text_Black_High};
    --color-text-white: #{$colorL_Text_White};
    --color-text-white-low: #{$colorL_Text_White_Low};
    --color-text-medium: #{$colorL_Text_Medium};
    --color-text-low: #{$colorL_Text_Low};
    --color-text-black-low: #{$colorL_Text_Black_Low};
    --color-text-yellow: #{$color-text-yellow};
    --color-under-construction: #{$color_Under_Construction};
    --color-gray: #{$colorL_Gray};
    --color-gray-20: #{$colorL_Gray_20};
    --color-gray-30: #{$colorL_Gray_30};
    --color-gray-40: #{$colorL_Gray_40};
    --color-gray-800: #{$colorL_Gray_800};
    --color-gray-600: #{$colorL_Gray_600};
    --color-gray-700: #{$colorL_Gray_700};

    --color-light-gray: #{$colorL_Light_Gray};
    --color-dark-gray: #{$colorL_Dark_Gray};
    --color-very-dark-gray: #{$colorL_Very_Dark_Gray};
    --color-stroke: #{$colorL_Stroke};
    --color-mini-button: #{$colorL_Mini_Button};
    --color-mini-button-reverse: #{$colorL_Mini_Button_Reverse};
    --color-secondary: #{$colorL_Secondary};
    --color-error: #{$colorL_Error};
    --color--semantic-error: #{$colorL_Semantic_Error};
    --color-dark-mode-error: #{$colorD_Error};
    --color-deleted-border-unselect: #{$color_Deleted_Border_Unselect};
    --color-deleted-background-unselect: #{$color_Deleted_Background_Unselect};
    --color-red-15: #{$colorL_Red_15};
    --color-success: #{$colorL_Success};
    --color-green-15: #{$colorL_Green_15};
    --color-green-30: #{$colorL_Green_30};
    --color-green: #{$colorL_Green};
    --color-dark-green: #{$colorL_Dark_Green};

    --color-blue: #{$colorL_Blue};
    --color-purple: #{$colorL_Purple};
    --color-purple-200: #{$colorL_Purple_200};
    --color-purple-20: #{$colorL_Purple_Opacity_20};
    --color-dark-purple-25: #{$colorL_Dark_Purple_Opacity_25};
    --color-indigo: #{$colorL_Indigo-100};
    --color-opacity-5: #{$colorL_Opacity_5};
    --color-opacity-15: #{$colorL_Opacity_15};
    --color-gradient-main: #{$colorL_Gradient_Main};
    --color-gradient-blue: #{$colorL_Gradient_Blue};
    --color-gradient-light-blue: #{$colorL_Gradient_LightBlue};
    --color-gradient-brand-secondary: #{$colorL_Gradient_Brand_Secondary};
    --color-gradient-menu-item-page: #{$colorL_Gradient_Menu_Item_Page};
    --color-background-light-mode: #{$colorL_Background_Light_Mode};
    --color-group-background-light-mode: #{$colorL_Group_Background_Light_Mode};
    --color-background-dark-mode: #{$colorL_Background_Dark_Mode};
    --color-light-mode-black: #{$colorL_Background_Light_Mode_Black};
    --color-background-black: #{$colorL_Background_Black};
    --color-background-modal: #{$colorL_Background_Modal};
    --color-background-modal-blur: #{$colorL_Background_Modal_Blur};
    --color-background-yellow: #{$colorL_Background_Yellow};
    --toastify-color-success: #{$colorL_Success};
    --toastify-color-error: #{$colorL_Error};
  }

  &[data-theme="dark"] {
    --color-mode: #{$colorD_Mode};
  }
}
