.Container {
  min-height: 100vh;
  background: var(--color-gray-20);
}

.Header {
  display: flex;
  padding: 20px 12px;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--color-gray-20);
  z-index: 1;
}

.BackWrapper {
  position: absolute;
  left: 16px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  .Back {
    width: 100%;
    height: 100%;
  }
}

.Title {
  align-self: stretch;
  color: var(--color-text-black-high);
  text-align: center;
  margin-inline: 35px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ActionBtn {
  position: absolute;
  right: 16px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.Items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
  padding: 65px 16px 20px;
  background: var(--color-gray-20);
}
.EmptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;

  h5 {
    text-align: center;
  }
}
