@import "src/assets/styles/mixins";

.Category {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  scrollbar-width: none;
}
.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
}
.Name {
  color: var(--color-text-black-high);
  @include text-truncate-ellipsis(1);
  word-break: break-word;
  padding-right: 8px;
}
.All {
  color: var(--color-text-black-low);
  cursor: pointer;
  min-width: max-content;
}

.Cards {
  display: flex;
  gap: 16px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.Horizontal {
    overflow: auto;
    padding-left: 16px;
  }

  &.Vertical {
    flex-direction: column;
    padding: 0 16px;
  }
}
