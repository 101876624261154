.MenuCard {
  display: flex;
  border-radius: 16px;
  background-color: white;
  //overflow: hidden;
  position: relative;

  &.Small {
    padding: 12px;
    .ImageContainer {
      margin-right: 12px;
      height: 106px;
      .Image {
        width: 100px;
        height: 106px;
        border-radius: 12px;
        object-fit: cover;
      }
    }
    .DetailContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 112px);
      justify-content: space-between;
      .Tags {
        margin-bottom: 12px;
      }
      .Title {
        margin-bottom: 8px;
        font-size: 16px;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.Medium {
    width: 298px;
    min-width: 298px;
    flex-direction: column;

    &:last-child {
      margin-right: 16px;
    }
    .ImageContainer {
      .Image {
        width: 100%;
        height: 224px;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }
      .FavoriteAndTimeToMake {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px;
        position: absolute;
        top: 0;
        .TimeToMake {
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 2px;
          color: white;
          border-radius: 12px;
          background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
          backdrop-filter: blur(8px);
        }
      }
    }
    .DetailContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px;
      min-height: 126px;
      .Tags {
        margin-bottom: 12px;
      }
      .Title {
        margin-bottom: 8px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &.Large {
    flex-direction: column;
    .ImageContainer {
      height: 224px;
      min-height: 224px;
      min-width: 100%;
      .Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }
      .FavoriteAndTimeToMake {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px;
        position: absolute;
        top: 0;
        .TimeToMake {
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 2px;
          color: white;
          border-radius: 12px;
          background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
          backdrop-filter: blur(8px);
        }
      }
    }
    .DetailContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px;
      min-height: 126px;
      .Tags {
        margin-bottom: 12px;
      }
      .Title {
        margin-bottom: 8px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.WithoutImage {
    width: 100%;
    flex-direction: column;

    &:last-child {
      margin-right: 16px;
    }
    .ImageContainer {
      display: none;
    }
    .DetailContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px;
      min-height: 126px;
      position: relative;
      .Favorite {
        position: absolute;
        top: 12px;
        right: 12px;
      }
      .Tags {
        margin-bottom: 12px;
      }
      .Title {
        margin-bottom: 8px;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }


  .Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .Price {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }
}
