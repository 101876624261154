@import "src/assets/styles/_variables";
.Basket {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;

  .OrderItemsWrapper {
    margin: 0 0 40px;

    > * + * {
      margin-top: 20px;
    }
  }
  .BasketAllItems{
    color: var( --color-inverse-75);
    font-weight: 600;
    padding-top: 40px;
  }

  .ConfirmOrderBtn {
    z-index: 5;
    position: fixed;
    bottom: 30px;
    left: 0;
    margin: 0 16px;
    width: calc(100% - 32px);
    transition: $defaultTabBarTransition;
    &:active {
      scale: 0.95;
    }
    background-color: var(--color-preferred-secondary-bg);


    .CTAButtonName {
      color: var(--color-preferred-secondary-text);
    }

    .Price {
      h4 {
        color: var(--color-preferred-secondary-text);
      }
    }
  }

  &.isBasketEmpty {
    padding-bottom: 0;

    .BasketHeroSection {
      margin-bottom: 0;
    }
  }
}

.BasketMainSection {
  padding: 0 16px 128px 16px;
}

.AddMessageForTheRestaurant {
  margin-bottom: 60px;

  h6 {
    margin-bottom: 14px;
  }

  .AddMessageTextareaContainer {
    textarea {
      height: 111px;
      padding: 12px;
    }
  }
}
