@import "src/assets/styles/_variables";

.MenuItem {
  overflow-x: hidden;
  position: relative;

  .MenuItemCoverImage,
  .MenuItemCarouselImage {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }

  .MenuItemModifications {
    padding: 16px 16px 32px;

    > * + * {
      margin-top: 16px;
    }
  }
  .MenuItemFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: rgba(255, 255, 255, 0.65);
    position: fixed;
    backdrop-filter: blur(4px);
    bottom: 0;
    width: 100%;
    padding: 12px 16px;
    z-index: 10;

    .AnimatedPlusMinusButtonContainer {
      flex: 1;
      position: sticky;
    }

    .CTAButton {
      flex: 3;
      bottom: 0;
      height: 56px;
    }
  }

  .AddToBasketBtn,
  .SaveChanges {
    &:active {
      scale: 0.95;
    }
  }
}

.MenuItemInfoContainer {
  min-height: 170px;
  h2 {
    margin-bottom: 12px;
  }

  &.WithPhoto {
    padding: 16px 16px 0;
  }
  &.WithoutPhoto {
    padding: 68px 16px 0;
  }
}

.MenuItemSlider {
  height: 420px;
  z-index: -1;

  .SwiperSlide {
    height: 100%;
  }
}
.MenuItem {
  > div {
    &:nth-last-child(2) {
      margin-bottom: 80px;
    }
  }
}
