@import "src/assets/styles/_variables";

.CloseButton {
  background: var(--color-mini-button);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;

  &.typeA {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }

  &.typeB {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: none;
  }

  &.typeC {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    background-color: var(--color-background-light-mode);
  }

  &.typeD {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: none;
    background-color: var(--color-mini-button);
  }

  &.typeE {
    width: 100px;
    height: 100%;
    border-radius: 8px;
    border: none;
    background-color: var(--color-mini-button);

    &:hover {
      border: none;
      background-color: var(--color-group-background-light-mode);
    }

    svg {
      width: 52px;
      height: 60px;
    }
  }

  &.typeF {
    padding: 4px;
    border: none;
    border-radius: 6px;

    svg {
      width: 40px;
      height: 40px;

      path {
        stroke-width: 1;
      }
    }

    &:hover {
      border: none;
      background-color: var(--color-mini-button);
    }

    &:active {
      border: none;
      background-color: var(--color-group-background-light-mode);
    }
  }

  &.typeG {
    padding: 4px;
    border: none;
    border-radius: 50%;

    svg {
      width: 40px;
      height: 40px;

      path {
        stroke-width: 1;
      }
    }

    &:hover {
      border: none;
      background-color: var(--color-mini-button);
    }

    &:active {
      border: none;
      background-color: var(--color-group-background-light-mode);
    }
  }

  &.typeH {
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 8px;
    background: var(--color-inverse-75);

    svg {
      width: 28px;
      height: 28px;
      svg {
        stroke: white;
      }

      path {
        stroke: var(--color-text-white);
      }
    }

    &:hover {
      border: none;
      background-color: var(--color-inverse-75);
      svg path {
        stroke: var(--color-text-white);
      }
    }

    &:active {
      border: none;
      background-color: var(--color-background-black);
      svg path {
        stroke: var(--color-text-white);
      }
    }
  }
  &.typeR {
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-mini-button);

    svg {
      width: 28px;
      height: 28px;
      path {
        stroke: var(--color-text-black);
      }
    }

    &:hover {
      border: none;
      background-color: var(--color-group-background-light-mode);
    }

    &:active {
      border: none;
      background-color: var(--color-background-black);
      svg path {
        stroke: var(--color-text-white);
      }
    }
  }

  &.typeM {
    width: 48px;
    height: 48px;
    border: none;
    background-color: transparent;
    svg {
      width: 40px;
      height: 40px;
      path {
        stroke: var(--color-text-white);
      }
    }

    &:hover {
      background-color: transparent;
      border: none;
    }

    &:active {
      border: none;
      background-color: transparent;
      svg path {
        stroke: var(--color-text-white);
      }
    }
  }

  &.typeZ {
    width: 48px;
    height: 48px;
    border: none;
    background-color: transparent;
    svg {
      width: 40px;
      height: 40px;
      path {
        stroke: var(--color-text-black);
      }
    }

    &:active {
      border: none;
      background-color: transparent;
      svg path {
        stroke: var(--color-text-black);
      }
    }
  }

  &.typeS {
    width: 34px;
    height: 34px;
    border-radius: 50%;

    svg {
      path {
        stroke: #57636c;
        stroke-width: 2px;
      }
    }
    &:hover {
      background: var(--color-mini-button);
      border: 1px solid transparent;
    }
  }
  &.typeT{
    background-color: var(--color-gray-700);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    svg {
      path {
        stroke: var(--color-text-white);
      }
    }
    &:hover {
      background-color: var(--color-gray-700);
      border: 1px solid transparent;
    }
    &:active {
      background-color: var(--color-gray-700);
      svg path {
        stroke: var(--color-text-white);
      }
    }
  }
  &.typeP {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: var(--color-gray-20);
    padding: 12px;
    svg {
      path {
        stroke: #57636C;
        stroke-width: 2px;
      }
    }
    &:hover {
      background: var(--color-mini-button);
      border: 1px solid transparent;
    }
  }

  &:hover {
    background-color: var(--color-background-light-mode);
    border: 1px solid var(--color-inverse-25);
  }

  &:active {
    background-color: var(--color-stroke);
    border: 1px solid transparent;

    svg path {
      stroke: var(--color-text-black);
    }
  }
}
