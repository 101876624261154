.Modal {
  .Background {
    bottom: 0;

    .Container {
      background-color: var(--color-background-light-mode);
      border-radius: 24px 24px 0 0;

      .ModalClassName {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }
}

.Header {
  width: 100%;
  padding: 24px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .Title {
    margin: 0 auto;
  }

  .HeaderCloseButton {
    position: absolute;
    top: 20px;
    right: 16px;
  }
}

.Body {
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0 16px 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  .BodyActionsContainer {
    .BodyActions {
      display: flex;
      justify-content: space-between;
    }
  }

  .BodySortContainer {
    .BodySortWrapper {
      margin-top: 20px;
      display: flex;
      gap: 12px;
      overflow-x: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.Footer {
  width: 100%;
  padding: 12px 16px;
  margin-top: auto;
  display: flex;
  gap: 24px;

  .ClearBtn {
    width: fit-content;
    height: 52px;
    padding: 14px 18px;
    text-decoration: underline;
    text-wrap: nowrap;

    h6 {
      font-size: 16px;
    }
  }

  .ApplyBtn {
    height: 52px;
    background-color: var(--color-admin-primary);

    h6 {
      font-size: 16px;
    }
  }
}
