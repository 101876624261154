@import "src/assets/styles/mixins";

.MyProfileEdit {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .MyProfileEditInfo {
    .MyProfileEditEditButton.isDisabled {
      h4 {
        color: var(--color-inverse-50);
      }
    }

    .MyProfileEditEditButton {
      h4 {
        color: var(--color-text-black);
      }
    }
  }
}

.MyProfileEditHeader {
  padding: 16px;
  display: flex;
  align-items: center;

  h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

.MyProfileEditInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 60px;
  padding: 0 16px;

  .MyProfileEditGuestImageContainer {
    width: 100px;
    height: 100px;
    background-color: var(--color-light-gray);

    .GuestProfileWithIconAvatar {
      width: 80px;
      height: 80px;
    }
    .GuestProfileWithIconProfilePicture {
      width: 100%;
      height: 100%;
    }
  }

  .GuestName {
    margin-top: 4px;
    text-align: center;
    @include text-truncate-ellipsis(2);
    word-break: break-all;

  }

  .react-international-phone-input {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .MyProfileEditGuestForm {
    padding: 32px 0;
    .DropdownSelectedItem {
      border-radius: 50px;
      background-color: var(--color-mini-button);
      border: none;

      .DropdownLabel {
        background-color: transparent;
        left: 17px;
        top: 8px;
        font-size: 9px;
        line-height: 16px;

        &.isCentered {
          top: 50%;
          font-size: 14px;
        }
      }

      .DropdownSelectedItemTitle {
        margin-left: 8px;
      }
    }

    .DropdownOptions {
      border-radius: 20px;
    }

    .InputControlInput {
      border-radius: 50px;
      padding-left: 23px;
      background-color: var(--color-mini-button);
      border: none;
      min-width: calc(100% - 16px);
    }

    .InputControlLabel {
      background-color: transparent;
      left: 17px;
      top: 8px;
      font-size: 9px;
      line-height: 16px;

      &:before {
        background-color: transparent;
      }

      &.isCentered {
        top: 50%;
        font-size: 14px;
      }
    }
  }
}

.MyProfileGuestImageContainerLoading {
  width: 100px;
  height: 100px;
}
.GuestAccountInfoModalProfileButton {
  background-color: var(--color-primary);
}

.MyProfileGuestEditButton {
  width: 40px;
  height: 40px;
  border: 4px solid var(--color-text-white);
  background-color: var(--color-primary);
}

.MyProfileEditContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 26px;

  .MyProfileEditContentSection {
    display: flex;
    align-items: center;
    gap: 18px;
    width: 100%;
    margin-top: 32px;
    padding: 16px 0;
    position: relative;
    cursor: pointer;

    svg {
      transform: scaleX(-1);
    }
  }

  .MyProfileEditContentSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 40px;
    width: 80%;
    height: 1px;
    background-color: var(--color-gray);
  }
}
