@import "src/assets/styles/_variables";

.Search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;

  .SearchIconContainer {
    left: 14px;
  }

  &.typeA {
    background-color: var(--color-mini-button-reverse);

    .SearchInput {
      padding: 14px 0 14px 38px;
      color: var(--color-text-white);
    }
  }

  &.typeB {
    border: 1px solid var(--color-gray);
    background-color: var(--color-background-light-mode);
    max-width: 210px;

    .SearchInput {
      padding: 14px 16px 14px 38px;
      color: var(--color-text-black);
      line-height: 18px;

      &::placeholder {
        color: var(--color-inverse-25);
      }
    }

    .SearchIconContainer {
      svg {
        width: 16px;
        height: 16px;

        path {
          stroke: var(--color-text-black);
        }
      }
    }
  }

  &.typeC {
    background-color: var(--color-mini-button-reverse);

    .SearchIconContainer {
      svg path {
        width: 24px;
        height: 24px;
        stroke: var(--color-text-white);
      }
    }

    .SearchInput {
      padding: 14px 0 14px 38px;
      color: var(--color-text-white);
    }
  }

  &.typeD {
    background: var(--color-group-background-light-mode);

    .SearchIconContainer {
      svg path {
        width: 16px;
        height: 16px;
        stroke: var(--color-text-black);
      }
    }

    .SearchInput {
      padding: 14px 0 14px 38px;
      color: var(--color-text-black);
    }
  }

  &.typeS {
    background: var(--color-gray-30);
    color: var(--color-text-black);

    .SearchInput {
      padding: 16px 0 16px 38px;
      color: var(--color-text-black);
    }
  }
}

.SearchInput {
  width: 100%;
  background: transparent;
  display: inline-block;

  &::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: var(--color-inverse-50);
  }
}

.SearchLeftSide {
  position: relative;
  flex-grow: 1;
}

.SearchIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.SearchRightSide {
  button {
    padding: 14px;
    background-color: transparent;
    display: flex;
    align-items: center;
  }
}

.SearchRightSideC {
  .SearchRightSideCBtn {
    padding: 12px;
    background-color: transparent;
    display: flex;
    align-items: center;
  }
}
