@import "src/assets/styles/_variables";

.DashBoardInformationSection {
  padding: 16px 16px 0;
  border-radius: 24px 24px 0 0;
  background: var(--color-mini-button);
  min-height: calc(100vh - 256px);
  .DashBoardInformationSectionGameArea {
    display: flex;
    gap: 8px;
    .DashBoardInformationSectionBanner {
      cursor: pointer;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      overflow: hidden;
      border-radius: 12px;

      backdrop-filter: blur(13px);
      width: 50%;
      &.DashboardFunZoneBanner {
        border: 2px solid #4042e2;
        background: linear-gradient(
          180deg,
          rgba(64, 66, 226, 0.2) 0%,
          rgba(79, 82, 255, 0.2) 100%
        );
        &::before {
          content: "";
          position: absolute;
          width: 9px;
          height: 36px;
          flex-shrink: 0;
          border-radius: 3px;

          background: linear-gradient(
            180deg,
            rgba(64, 66, 226, 0.8) 0%,
            rgba(79, 82, 255, 0.8) 100%
          );
        }
      }
      &.DashboardChatBanner {
        border: 2px solid var(--Brand-colors-Primary, #ffca40);
        background: rgba(255, 202, 64, 0.2);
        &::before {
          content: "";
          position: absolute;
          width: 9px;
          height: 36px;
          flex-shrink: 0;
          border-radius: 3px;
          background: #ffca40;
        }
      }

      &::before {
        left: -5px;
      }

      .DashBoardInformationSectionBannerContent {
        height: 100%;
        padding-left: 15px;
        width: 74%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 6px;
        padding-bottom: 16px;
        gap: 4px;

        .DashBoardInformationSectionBannerContentTitle {
          color: var(--color-text-black);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-wrap: nowrap;
        }
      }
      img {
        position: absolute;
        right: -23px;
        //top: -7px;
      }
    }
  }
}

.DashBoardGuestCount {
  padding-top: 24px;
  padding-left: 8px;
  margin-bottom: 12px;
  h6 {
    color: var(--color-text-black);
  }
}

.DashBoardGuestInformation {
  display: flex;
  position: fixed;
  bottom: 25px;
  left: 16px;
  right: 16px;
  z-index: 15;
  align-items: center;
  gap: 16px;
  transition: $defaultTabBarTransition;
  .GuestInformationPayButtonWrapper {
    justify-content: space-between;
    text-align: left;
    .GuestPayDetail {
      display: flex;
      align-items: center;
      gap: 12px;

      .OrderItemsToBePaidCount {
        min-width: 24px;
        height: 24px;
        background-color: var(--color-admin-primary);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h3 {
        color: var(--color-text-white);
      }
    }
    .Price{
      h4{
        color: var(--color-text-white);
      }
    }
  }
}

.MyOrders {
  background-color: var(--color-gray-800);
  .ModalClassname {
    background-color: var(--color-text-white);
  }
}
.DashBoardChat {
  height: 400px;
  margin-top: 33px;
}

.OrderCard {
  width: calc((100% / 3) - 16px);
  margin: 8px;
}
