@import "src/assets/styles/_variables";

.Container {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--color-gray-20);
  background: var(--color-glass-white-90);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  transition: $defaultTabBarTransition;
  z-index: 20;

  &.isHidden {
    bottom: -77px;
  }
}
.Part {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Icon {
  cursor: pointer;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  position: relative;

  svg {
    width: 24px;
    height: 24px;
  }
}


.Icon,
.Center {
  &.isDisabled {
    pointer-events: none;
  }
}


.BadgeContainer {
  display: flex;
  padding: 0 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 7px;
  top: 7px;
  border-radius: 10px;
  background: var(--color-admin-primary);
  min-width: 14px;

  .Text {
    color: var(--color-text-black-high);
    text-align: center;
  }
}

.Badge {
  display: flex;
  width: 8px;
  height: 8px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  right: 7px;
  top: 7px;
  background: var(--color-admin-primary);
}

@media (max-width: $mobileXS) {
  .Container {
    gap: 10px;
  }
  .Center {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
  .Part {
    gap: 0;
  }
}